<template>
  <section class="card">
    <img :src="MOBILE" class="hero-img" alt="Healthy Together en un dispositivo móvil">
    <h1 class="main-text">Descargar Healthy Together</h1>
    <p class="sub-text">Plataforma de Salud Pública y Seguridad</p>
    <appstorelinks></appstorelinks>
  </section>
</template>

<script>
import DetectAndServe from '@/redirect.js';
import { MOBILE } from '@/constants/cloudinary';
import AppStoreLinks from '@/components/basic/AppStoreLinks.vue';

export default {
  name: 'Mobile',
  components: {
    AppStoreLinks,
  },
  created() {
    DetectAndServe();
    this.MOBILE = MOBILE;
  },
};
</script>

<style scoped="" lang="scss">
@import '../styles/global/card.scss';

.card {
  @include card-basic(#d3ebff);

  min-height: 40rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.hero-img {
  max-width: 16.8125rem;
  width: 100%;
  display: inline-block;
  padding-bottom: 1rem;

  @include bp-mediumsmall {
    padding-bottom: 2rem;
  }
}

.main-text {
  font-size: 1.7rem;
  line-height: 2rem;
  margin-bottom: 1rem;

  @include bp-mediumsmall {
    font-size: 3rem;
    line-height: 3.5rem;
    margin-bottom: 1.5rem;
  }
}

.sub-text {
  font-size: 1.3rem;
  line-height: 1.6rem;
  margin-bottom: 1.5rem;
  color: #505050;

  @include bp-mediumsmall {
    font-size: 2rem;
    line-height: 2.5rem;
    margin-bottom: 2rem;
  }
}

.stores {
  max-width: 18.125rem;
  grid-template-columns: 1fr;

  @include bp-mediumsmall {
    grid-template-columns: repeat(2, 1fr);
  };
}
</style>
